* {
  box-sizing: border-box;
}

.App {
  min-height: 100vh;
  --accent-red: hsl(356deg 86% 55%);
  --darkgrey: hsl(230deg 5% 44%);
  --lightgrey-background: #eeeeee;
}

/*
* {
  border: 1px solid red !important;
}
*/

body {
  margin: 0px;
  font-family: "Lato", sans-serif;
}

a:visited {
  color: inherit;
}

.intro {
  display: block;
  min-height: 100vh;
}

.intro::before {
  background-image: url("https://raw.githubusercontent.com/cph-kiwi/cph-kiwi-images/master/karen-background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top right;
  min-height: 100vh;
  content: "";
  position: absolute;
  width: 100%;
  z-index: -1;
}

.main-header-logo a {
  font-family: "Playfair Display", sans-serif;
  font-size: 30px;
  text-decoration: none;
}

.main-header-logo a:hover {
  color: var(--accent-red);
}

.main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  position: fixed;
  top: 0;
  padding-left: 32px;
  padding-right: 32px;
  width: 100%;
}

.main-nav ul {
  text-align: center;
}

.main-nav li {
  display: inline;
}

.main-nav a {
  display: inline-block;
  padding: 0.5em 1.5em;
  color: inherit;
  text-decoration: none;
}

.main-nav a:hover {
  color: var(--accent-red);
}

.intro-container {
  padding-left: 64px;
  padding-right: 64px;
  padding-top: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 50%;
}

.intro-h1 {
  font-family: "Playfair Display", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 50px;
  color: black;
}

.intro-h2 {
  font-style: normal;
  font-weight: normal;
  color: black;
  margin-block-end: 1.5em;
}

.intro-h2 a {
  color: black;
}

.intro-link-to-more a {
  color: black;
  text-decoration: none;
}

.intro-link-to-more a:hover {
  color: var(--accent-red);
}

.intro-link-to-guest-book {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: black;
  text-decoration: none;
  width: 157px;
  height: 32px;
  text-align: center;
  line-height: 18px;
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.guest-book-section {
  background: var(--lightgrey-background);
  background-size: cover;
  display: block;
  min-height: 100vh;
  padding-top: 10vh;
  /* padding-right: 32px;
	padding-left: 32px; */
  padding-bottom: 50px;
  color: black;
}

.guest-book-subsection-1 {
  display: flex;
  flex-direction: column;
  text-align: left;
  min-height: calc(100vh - 60px);
}

.guest-book-subsection-2 {
  align-items: left;
}

.guest-book-subsection-2 button {
  background: var(--lightgrey-background);
  text-align: left;
  border: none;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
  margin-left: 32px; /* added */
}

.guest-book-h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
}

.messages-container {
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  justify-items: start;
  flex-wrap: wrap;
  height: 500;
  width: 100%;
  overflow: auto;
  margin: 10px;
  padding-left: 32px;
  padding-right: 32px;
  align-content: start;
}

.guest-book-subsection-2 ul {
}

.guest-book-subsection-2 li {
  list-style-type: none;
}

.guestbook-message {
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 370px;
  height: item * 50;
  background: white;
  margin: 10px;
  box-shadow: 4px 8px 16px rgba(0, 0, 0, 0.13);
}

.guestbook-message-message {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  width: 100%;
}

.guestbook-message-subsection {
  display: flex;
  flex-direction: row;
}

.guestbook-message-name {
  flex: 1;
  font-family: "Montserrat", sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  text-align: right;
}

.guestbook-message-date {
  flex: 1;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #717171;
}

.guest-book-form {
  position: absolute;
  top: 150%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  box-shadow: 4px 30px 60px rgba(0, 0, 0, 0.53);
  width: 400px;
  height: 400px;
  padding-top: 28px;
  padding-left: 22px;
  padding-right: 22px;
  padding-bottom: 22px;
}

.guest-book-form-section {
  display: flex;
  flex-direction: column;
  align-content: space-around;
}

.guest-book-form-from-label {
  text-align: left;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  width: 100%;
  padding-bottom: 4px;
}

.form-input-from {
  display: block;
  resize: none;
  width: 100%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
}

.guest-book-form-message-label {
  /*position: absolute; */
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: black;
  padding-top: 12px;
  padding-bottom: 4px;
  text-align: left;
}

.form-input-message {
  display: block;
  resize: none;
  width: 100%;
  height: 210px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 22px;
}

.form-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 28px;
}

.form-cancel-button {
  flex: 1;
  padding: 0;
  height: 22px;
  border: none;
  background: none;
  justify-content: center;
  color: darkgray;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
}

.form-submit-button {
  flex: 1;
  padding: 0;
  height: 22px;
  border: none;
  background: none;
  justify-content: center;
  color: black;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
}

.form-submit-button svg {
  height: 16px;
  width: 16px;
}

.contact-section {
  background: var(--lightgrey-background);
  color: black;
  background-size: cover;
  display: block;
  min-height: 100vh;
  padding-top: 40vh;
  padding-right: 50px;
  padding-left: 50px;
  padding-bottom: 50px;
}

.contact-subsection-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-subsection-2 {
  display: flex;
  height: 32px;
  justify-content: space-between;
  align-items: center;
}

.contact-h1 {
  font-family: "Prata", serif;
  font-weight: 400;
  font-size: 64px;
  line-height: 87px;
  margin: 0;
  padding-bottom: 14px;
}

.contact-subsection-2 a {
  justify-content: flex-start;
  padding-left: 9px;
  padding-right: 9px;
}

.contact-subsection-2 img {
  max-height: 100%;
}

.footer {
  padding-left: 64px;
  padding-right: 64px;
  padding-top: 76px;
  background-size: cover;
  flex-direction: column;
  width: 100%;
  background: var(--lightgrey-background);
  display: flex;
}

@media (max-width: 800px) {
  .main-header {
    padding-left: 20px;
    padding-right: 20px;
  }

  .main-nav ul {
    display: flex;
  }

  .main-nav a {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .intro::before {
    opacity: 0.2;
  }

  .intro-container {
    padding-left: 50px;
    padding-right: 50px;
    width: 100%;
  }

  .intro-columns-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .intro-text-column {
    padding-bottom: 50px;
    width: 100%;
  }
  .intro-h3 {
    font-size: 20px;
  }

  .guestbook-message {
    width: 310px;
  }

  .messages-container {
    padding-left: 0px;
    padding-right: 0px;
    margin: 0px;
  }

  .guest-book-form {
    width: 310px;
  }

  .contact-h1 {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 33px;
  }
}
